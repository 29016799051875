// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Icon__Jsx3 = require("../../../uikit/reason/atoms/Icon/Icon__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var JsxRuntime = require("react/jsx-runtime");
var Theme__Color__Vars = require("../../../reason/common/Theme/Theme__Color__Vars.bs.js");
var FragmentErrorBoundary = require("../../common/ErrorHandling/FragmentErrorBoundary.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var UnselectedUnionMemberError = require("../../common/ErrorHandling/UnselectedUnionMemberError.bs.js");
var ClientPropertyLocation_property_graphql = require("../../../__generated__/ClientPropertyLocation_property_graphql.bs.js");
var ClientPropertyLocation_propertyLocation_graphql = require("../../../__generated__/ClientPropertyLocation_propertyLocation_graphql.bs.js");

var convertFragment = ClientPropertyLocation_property_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(ClientPropertyLocation_property_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, ClientPropertyLocation_property_graphql.node, convertFragment);
}

var PropertyFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

var convertFragment$1 = ClientPropertyLocation_propertyLocation_graphql.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(ClientPropertyLocation_propertyLocation_graphql.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, ClientPropertyLocation_propertyLocation_graphql.node, convertFragment$1);
}

var PropertyLocationFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1
};

function ClientPropertyLocation$Address(props) {
  var locationDisplay = props.locationDisplay;
  var address = props.address;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: address !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: address
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: locationDisplay,
                                    className: "text-lg text-zinc-600"
                                  })
                            ]
                          }) : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: locationDisplay
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(Icon__Jsx3.make, {
                                                  icon: "Lock",
                                                  size: 16,
                                                  color: Theme__Color__Vars.chicago
                                                }),
                                            JsxRuntime.jsx("div", {
                                                  children: "Hidden Address"
                                                })
                                          ],
                                          className: "flex flex-row items-center gap-1 lg:before:content-['('] lg:after:content-[')']"
                                        }),
                                    className: "text-xl text-zinc-600"
                                  })
                            ]
                          }),
                    className: "flex flex-col lg:flex-row items-baseline gap-2"
                  }),
              className: "text-zinc-700 font-medium text-2xl sm:text-3xl"
            });
}

var Address = {
  make: ClientPropertyLocation$Address
};

function ClientPropertyLocation$ByPropertyLocation(props) {
  var propertyLocation = RescriptRelay_Fragment.useFragment(ClientPropertyLocation_propertyLocation_graphql.node, convertFragment$1, props.propertyLocation);
  var variant = propertyLocation.NAME;
  if (variant !== "PropertyLocationWithVisibleAddress") {
    if (variant === "UnselectedUnionMember") {
      return UnselectedUnionMemberError.raise("Unselected property location " + propertyLocation.VAL);
    } else {
      return JsxRuntime.jsx(ClientPropertyLocation$Address, {
                  address: undefined,
                  locationDisplay: propertyLocation.VAL.locationDisplay
                });
    }
  }
  var match = propertyLocation.VAL;
  return JsxRuntime.jsx(ClientPropertyLocation$Address, {
              address: match.address,
              locationDisplay: match.locationDisplay
            });
}

var ByPropertyLocation = {
  make: ClientPropertyLocation$ByPropertyLocation
};

function ClientPropertyLocation$WithoutErrorBoundary(props) {
  var property = RescriptRelay_Fragment.useFragment(ClientPropertyLocation_property_graphql.node, convertFragment, props.property);
  return JsxRuntime.jsx(ClientPropertyLocation$ByPropertyLocation, {
              propertyLocation: property.propertyLocation.fragmentRefs
            });
}

var WithoutErrorBoundary = {
  make: ClientPropertyLocation$WithoutErrorBoundary
};

function ClientPropertyLocation(props) {
  return JsxRuntime.jsx(FragmentErrorBoundary.make, {
              fragmentDisplayName: "location",
              children: JsxRuntime.jsx(ClientPropertyLocation$WithoutErrorBoundary, {
                    property: props.property
                  })
            });
}

var make = ClientPropertyLocation;

exports.PropertyFragment = PropertyFragment;
exports.PropertyLocationFragment = PropertyLocationFragment;
exports.Address = Address;
exports.ByPropertyLocation = ByPropertyLocation;
exports.WithoutErrorBoundary = WithoutErrorBoundary;
exports.make = make;
/* Icon__Jsx3 Not a pure module */
